import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DPEPage = () => (
  <Layout>
    <SEO title="デジタルプリント・DPE" />
    <div
      className="flex h-[20vh] bg-cover bg-center bg-opacity-25 justify-center content-center"
      style={{ backgroundImage: `url('../dpe-header.webp')` }}
    >
      <p className="text-textblack text-center text-3xl lg:text-4xl self-center">デジタルプリント・DPE</p>
    </div>
    <div className="container space-y-5 px-5 lg:px-0 my-8 justify-center content-center">
      <p className="text-textblack text-xl text-center">プロが一枚一枚、色補正します。</p>
      <p className="text-textblack text-xl text-center">ワイド四切まで最短 翌日仕上がり</p>
      
      <p className="text-textblack text-center text-lg">
        当店にてプリントいたします（ワイド四切まで）<br/>
      </p>
      <div className="container mx-auto px-5 max-w-screen-md space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">料金</p>
        <p className="text-textblack text-lg indent-5">L判 &emsp; 50円/枚</p>
        <p className="text-textblack text-lg indent-5">他のサイズも取り扱いしております。</p>
      </div>
      <div className="container mx-auto px-5 max-w-screen-md space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">注意事項</p>
        <ul class="list-disc ml-10">
          <li className="text-textblack">ワイド四切より大きいサイズは外注対応となるためお時間をいただきます。</li>
          <li className="text-textblack">お預かりいたしましたメディアの取扱には十分注意いたしますが、思わぬ事故等が発生することが考えられますので、お持ちいただくメディアにつきましては、念の為、予めお客様の方でバックアップをお取りいただきますようお願い申し上げます。</li>
          <li className="text-textblack">対応メディア対応画像フォーマット以外で画像を記録するデジタルカメラは、画像処理ソフト及び対応メディアを用いて、JPEG、TIFF等の対応画像フォーマットに変換してお持ちください。また内蔵メモリに画像を記録するデジタルカメラは、USBメモリ、CD等の対応メディアにコピーしてお持ちください。</li>
          <li className="text-textblack">メディアはケースに入れてお持ちください。</li>
          <li className="text-textblack">デジタルプリントではお持ちいただいたデータを、写真として適正な補正をして出力します。補正を希望されない場合は、補正不要にてご注文ください。</li>
          <li className="text-textblack">お客様がお手持ちのデジタルカメラ・スマートフォンやパソコン等のモニターで見るイメージと仕上がり印象は異なる場合があります。また、これを理由とした再プリントや返品はお受けできません。</li>
          <li className="text-textblack">メディアが読み込めない場合やウイルス感染の恐れがある場合などは、プリントせずにご返却させていただきますのでご了承ください。</li>
          <li className="text-textblack">色空間が異なる場合、色味が仕上がりとイメージで異なる場合があります。なお、プリント出力はsRGBで行います。</li>
          <li className="text-textblack">WindowsおよびMacで読み込めるフォーマットに書き込みしてください。</li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default DPEPage